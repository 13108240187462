/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
    Container,
} from 'react-bootstrap';

import axios from 'axios';

function Instagram() {
    const [media, setMedia] = useState([]);
    const [userig, setUserIg] = useState([]);
    const [loading, setLoading] = useState(true)
    const getData = async () => {
        try {
            let response = await axios.get(`https://graph.facebook.com/v12.0/17841404087184657/media?fields=media_url%2Clike_count%2Cusername&access_token=EAAG2Y7JVgmkBAPOZCTl6fYPcPGtMzZBephwgD72CdxObGZAQBFbpJwA4DbG1Ahf802Yaya2uBxI1EEYeIGUV70ZCRb0uWMZC8vLvFZARyhlb6q7GnuZB1xv3DgaIDWQ0bvw7s6yWJbnmBmvCPnklKC49WKi4J7BGgZC4Kp72r18EUxSXmJMvkZAcoTlNi1UpPZAQAZD&limit=12`)
            setMedia(response.data.data);
            setLoading(false);
        } catch (e) {
            console.log(e.message);

        }
    }
    const getUserIg = async () => {
        try {
            let response = await axios.get(`https://graph.facebook.com/v12.0/17841404087184657/?fields=username%2Cprofile_picture_url%2Cbiography%2Cfollows_count%2Cfollowers_count%2Cmedia_count%2Cname&access_token=EAAG2Y7JVgmkBAPOZCTl6fYPcPGtMzZBephwgD72CdxObGZAQBFbpJwA4DbG1Ahf802Yaya2uBxI1EEYeIGUV70ZCRb0uWMZC8vLvFZARyhlb6q7GnuZB1xv3DgaIDWQ0bvw7s6yWJbnmBmvCPnklKC49WKi4J7BGgZC4Kp72r18EUxSXmJMvkZAcoTlNi1UpPZAQAZD`);
            setUserIg(response.data);
        } catch (e) {
            console.log(e.message);

        }
    }

    useEffect(() => {
        getUserIg();
        getData();
    }, []);
    return (
        <div className="col-lg-12 my-5 py-5 insta-profile">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center p-5">
                        <h5 className="mb-5">Follow Us on Instragram</h5>
                        <img src={userig.profile_picture_url} width="100" height="100" className="mb-3 border border-1 rounded-circle" />
                        <h5>{userig.name}</h5>
                        <div className="row">
                            <div className="col">
                                <strong>{userig.media_count}</strong> <span style={{ marginRight: "30px" }}>Posts </span>
                                <strong>{userig.followers_count}</strong> <span style={{ marginRight: "30px" }}>Followers </span>
                                <strong>{userig.follows_count}</strong> <span style={{ marginRight: "30px" }}>Following </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="res-off">
                            <div className="row">
                                {
                                    media.map((photo, index) => {
                                        return (
                                            <div key={index} className="col-md-2 col-4 py-3">
                                                <img src={photo.media_url} className="img-fluid" />
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-12 text-center">
                                    <a href={`https://instagram.com/${userig.username}`} className="mt-5 btn btn-dark">Get More Post</a>
                                </div>
                            </div>
                        </div>
                        <div className="res-on">
                            <div className="row">
                                {
                                    media.map((photo, index) => {
                                        return (
                                            <>
                                                {
                                                    index >= 6 ? (
                                                        ''
                                                    ) : (
                                                            <div key={index} className="col-md-2 col-4 py-3">
                                                                <img src={photo.media_url} className="img-fluid" />
                                                            </div>
                                                        )
                                                }
                                            </>
                                        )
                                    })
                                }
                                <div className="col-12 text-center">
                                    <a href={`https://instagram.com/${userig.username}`} className="mt-5 btn btn-dark">Get More Post</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Instagram;
