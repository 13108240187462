import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
  Container,
} from 'react-bootstrap';

import Instagram from './Instagram';
import tgsLogo from '../../assets/img/tegalsari-logo.png';
import perteninLogo from '../../assets/img/pertenin-logo.png';
import rumahsemestaLogo from '../../assets/img/rumahsemesta-logo.png';
import megunaLogo from '../../assets/img/meguna-logo.png';

function Home() {
  return (
    <div>
      <Container className="content">
        <p data-aos="fade-up">Ubud is arguably the center of the island healthiness, literally speaking. At the heart of Ubud, Warung Semesta emerges as the haven for those seeking healthy fare.</p>

        <p data-aos="fade-up">A healthier lunch or dinner shouldn’t at any cost sacrifice the taste buds, aesthetic appearance, benefits to the body health, as well an appealing presentation. More so it should also offer diners chance to explore a new culinary journey, such as the sense of local contents and originality. The Warung Semesta in Ubud eatery street of Jalan Monkey Forest knows this crucial verdict. Semesta in fact was established to answer such quest.</p>

        <p data-aos="fade-up">With plenty of modern variety of illness often associated with engineered meat-related diseases, the true key to ultimate healthiness is going vegetarian way and staying organic. At Semesta not only these two issues are being the concern, they are going back to nature and true Balinese culinary tradition of eating vegetables from their own garden. There are variety of food stuff that only are grown in a really traditional Balinese backyard. Say Moringa and spinach.</p>

        <p data-aos="fade-up">Moringa, the highly recommended vegetable to cure illness related to be deficient in of A-vitamin and mineral, is the vegetable considered by Balinese as source of healthy food.</p>

        <p data-aos="fade-up">Bringing the true traditional food into modern style taste and presentation is no nonchalance. Uniquely, this type of food product are grown not in massive, tourist oriented plantation. It is the type of plant regularly found in the back yard of common Balinese housing compounds. Since these type of tree and vegetable are not the variety of industrial food aimed at major market, no fertilizer nor pesticide is needed. Naturally, since it is not massive.</p>

        <p data-aos="fade-up">In fact, Warung Semesta is part of the Rumah Semesta, a wholly new concept of accommodation featuring the three important parts of universe that conspires the existence of the living being, that is water, soil and air.</p>
      </Container>
      {/* <Instagram /> */}
      <Container className="text-center content" data-aos="fade-up">
        <p className="my-5">Warung Semesta part of</p>
        <div className="res-off">
          <div className="row">
            <div className="col-md-3 col-6">
              <a href="http://tegalsari-ubud.com"><img src={tgsLogo} width="150" /></a>
            </div>
            <div className="col-md-3 col-6">
              <a href="https://pertenin.com"><img src={perteninLogo} width="150" /></a>
            </div>
            <div className="col-md-3 col-6">
              <a href="https://rumahsemesta.com"><img src={rumahsemestaLogo} width="150" /></a>
            </div>
            <div className="col-md-3 col-6">
              <a href="https://megunaubud.com"><img src={megunaLogo} width="150" /></a>
            </div>
          </div>
        </div>
        <div className="res-on">
          <div className="row">
            <div className="col-3">
              <a href="http://tegalsari-ubud.com"><img src={tgsLogo} width="50" /></a>
            </div>
            <div className="col-3">
              <a href="https://pertenin.com"> <img src={perteninLogo} width="50" /></a>
            </div>
            <div className="col-3">
              <a href="https://rumahsemesta.com"> <img src={rumahsemestaLogo} width="50" /></a>
            </div>
            <div className="col-3">
              <a href="https://megunaubud.com"> <img src={megunaLogo} width="50" /></a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Home;
