import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Contact(props) {
  return (
    <Container className="content">
      <Row>
        <Col lg='4'>
          <h3>Warung Semesta</h3>
          <p>
            Jl. Monkey Forest, Padangtegal, Padangtegal Kelod, Ubud,<br />
            Gianyar - Bali , 80571
            </p>
          <p>
            Telephone: +62 361 970677<br />
            Whatsaap: +62 881 0374 21478<br />
            E-mail: warungsemestubud@gmail.com
            </p>
          <p>
            <a href="http://www.facebook.com/warungsemestaubud">
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
            <a href="http://www.instagram.com/warungsemesta">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
            <a href="https://wa.me/62881037421478">
              <FontAwesomeIcon icon={["fab", "whatsapp"]} />
            </a>
          </p>
        </Col>
        <Col lg='8'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.804602050786!2d115.26179452046536!3d-8.518343588627587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23d7307f002af%3A0xb27b1f2c7f3a7534!2sWarung%20Semesta!5e0!3m2!1sid!2sid!4v1638951941501!5m2!1sid!2sid" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
        </Col>
      </Row>

    </Container>
  );
}

export default Contact;
