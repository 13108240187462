import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
    Container,
    Row,
    Col,
    Carousel
} from 'react-bootstrap';
import Cooking1 from '../../assets/img/cookingclass1.jpg';
import Cooking2 from '../../assets/img/cookingclass2.jpg';
import Cooking3 from '../../assets/img/cookingclass3.jpg';
import Cooking4 from '../../assets/img/cookingclass4.jpg';

function Cooking(props) {
    return (
        <Container className="content text-center">
            <p data-aos="fade-up">
                Join our Unique hands-on culinary class Balinese or Vegetarian dishes available<br />
                Price : Rp. 300.000 per person, Including all ingredients, water, lunch, and recipe booklet. Participant minimum for 2 persons,<br />
                Time 09:00 - finish (around 4 hours)<br />
                Menu :<br />
            </p>
            <Row className="my-5">
                <Col className="image-cooking-lg">
                    <img src={Cooking1} className="img-fluid mx-2" alt="Warung Semesta Cooking Class" data-aos="fade-up"/>
                    <img src={Cooking2} className="img-fluid mx-2" alt="Warung Semesta Cooking Class" data-aos="fade-up" data-aos-delay="100"/>
                    <img src={Cooking3} className="img-fluid mx-2" alt="Warung Semesta Cooking Class" data-aos="fade-up" data-aos-delay="200"/>
                    <img src={Cooking4} className="img-fluid mx-2" alt="Warung Semesta Cooking Class" data-aos="fade-up" data-aos-delay="300"/>
                </Col>

                <Carousel className="image-cooking-sm"  data-aos="fade-up">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Cooking1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Cooking2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Cooking3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Cooking4}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Row>
            <Row>
                <Col lg="6" md="12" className="course-menu" data-aos="fade-up">
                    <h5>Balinese Dishes</h5>
                    <ul>
                        <li><p>Jukut Mecantok</p><span>Balinese style Gado - gado ) Assorted vegetables with peanut sauce Balinese</span></li>
                        <li><p>Tum Ayam</p><span>Minced chicken wrapped in banana leaf</span></li>
                        <li><p>Ikan Pepes</p><span>Steamed marinated fish wrapped in banana leaf</span></li>
                        <li><p>Balinese Nasi Goreng</p><span>Balinese Fried Rice</span></li>
                        <li><p>Be Celeng Base Manis</p><span>Pork in sweet soya sauce</span></li>
                        <li><p>Dadar Gulung</p><span>Balinese Pancake</span></li>
                    </ul>
                </Col>
                <Col lg="6" md="12" className="course-menu"  data-aos="fade-up">
                    <h5>Vegetarian Dishes:</h5>
                    <ul>
                        <li><p>Sup Kelor</p><span>Moringa leaves soup</span></li>
                        <li><p>Urab Kelor</p><span>Mixed moringa leaves with grated coconut and beans</span></li>
                        <li><p>Tum Tahu</p><span>Tofu and mushroom wrapped in banana leaf</span></li>
                        <li><p>Jukut Mecantok</p><span>Balinese style Gado - gado ) Assorted vegetables with peanut sauce Balinese</span></li>
                        <li><p>Balinese Nasi Goreng</p><span>Balinese Fried Rice</span></li>
                        <li><p>Sumur Tempe</p><span>Fermented soy bean in sweet soya sauce</span></li>
                        <li><p>Dadar Gulung</p><span>Balinese Pancake</span></li>
                    </ul>
                </Col>
                <Col lg="12" md="12" className="course-menu"  data-aos="fade-up">
                    <p>Price subject to 15% goverment tax and service</p>
                </Col>
            </Row>

        </Container>
    );
}

export default Cooking;
