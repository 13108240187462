import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
    Container,
    Nav,
    Navbar,
    Button
} from 'react-bootstrap';
import Logo from '../../assets/img/logo.png'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header() {
    return (
        <div className="main-header col-12" >
            <div className="top-nav">
                <Container className="py-1">
                    <div className="row">
                        <div className="col-6">
                            <strong>Call :</strong> +62 361 970677
                        </div>
                        <div className="col-6 text-end icon-link">
                            Follow Us :
                            <a href="http://www.facebook.com/warungsemestaubud">
                                <FontAwesomeIcon icon={["fab", "facebook"]} />
                            </a>
                            <a href="http://www.instagram.com/warungsemesta">
                                <FontAwesomeIcon icon={["fab", "instagram"]} />
                            </a>
                            <a href="https://wa.me/62881037421478">
                                <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="main-nav">
                <Container>
                    <Navbar bg="white" expand="lg">
                        <Container className="px-0" fluid>
                            <Navbar.Brand href="#">
                                <img
                                    src={Logo}
                                    height="50"
                                    className="d-inline-block align-top"
                                    alt="Warung Semesta logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                    <Nav
                                        className="mx-auto my-2 my-lg-0 "
                                        navbarScroll
                                    >
                                        <Link className="nav-link" to="/">Home</Link>
                                        <Link className="nav-link" to="/about">About Us</Link>
                                        <Link className="nav-link" to="/menu">Menu</Link>
                                        <Link className="nav-link" to="/cooking-course">Cooking Course</Link>
                                        <Link className="nav-link" to="/contact">Contact Us</Link>
                                    </Nav>
                                <Button href="https://wa.me/62881037421478" id='booking-table' variant="outline-success"><FontAwesomeIcon icon={["fab", "whatsapp"]} /> Whatsapp</Button>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Container>
            </div>
        </div>
    );
}

export default Header;
