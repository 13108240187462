/* eslint-disable jsx-a11y/img-redundant-alt */
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import 'react-slideshow-image/dist/styles.css'
import {
    Container,
    Image
} from 'react-bootstrap';

import { Link } from "react-router-dom";

import React from 'react';
import { Zoom } from 'react-slideshow-image';
import Slide1 from '../../assets/img/slide1.jpg';
import Slide2 from '../../assets/img/slide2.jpg';
import Slide3 from '../../assets/img/slide3.jpg';
import Batas from '../../assets/img/batas.png';

function Slider() {
    const images = [
        Slide1,
        Slide2,
        Slide3
    ];

    const zoomInProperties = {
        scale: 1.4,
        arrows: false,
        pauseOnHover: false,
        duration: 5000
    }
    return (
        <div>
        <Zoom {...zoomInProperties}>
            {images.map((each, index) => (
                <div key={index} style={{ width: "100%" }}  >
                    <Container>
                        <div className="text-slider">
                            <h2>Organic, Vegetarian and <br/>Non Vegetarian Restaurant</h2>
                            <h4>Healthier Plate, Exquisite Taste</h4>                            
                            <Link className="btn-slide" to="/menu">View Our Menu</Link>
                        </div>
                    </Container>
                    <div className="img-slide">
                        <img style={{ objectFit: "cover", width: "100%" }} src={each} alt={`Warung Semesta Image slider ${index+1}`}/>
                        <div className="overlay-img"></div>
                    </div>
                </div>
            ))}
        </Zoom>
        
        <Image className="batas-slider" src={Batas} fluid />
        {/* <div style={{'backgroundImage': `url(${Batas})`}} className="batas-slider"></div> */}

        </div>
    );
}

export default Slider;
