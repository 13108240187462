import "./App.css";
import Header from "./comp/header/Header";
import Slider from "./comp/header/Slider";
import Title from "./comp/header/Title";
import Home from "./comp/content/Home";
import About from "./comp/content/About";
import Contact from "./comp/content/Contact";
import Menu from "./comp/content/Menu";
import Cooking from "./comp/content/Cooking";
import Footer from "./comp/footer/Footer";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { BrowserRouter, Routes, Route } from "react-router-dom";

library.add(fab, fas);

function HomeRoute() {
  return (
    <div>
      <Header />
      <Slider />
      <Home />
      <Footer />
    </div>
  );
}
function AboutRoute() {
  return (
    <div>
      <Header />
      <Title titlenya="About Us" />
      <About />
      <Footer />
    </div>
  );
}
function MenuRoute() {
  return (
    <div>
      <Header />
      <Title titlenya="Our Menu" />
      <Menu />
      <Footer />
    </div>
  );
}
function CookingRoute() {
  return (
    <div>
      <Header />
      <Title titlenya="Cooking Course" />
      <Cooking />
      <Footer />
    </div>
  );
}
function ContactRoute() {
  return (
    <div>
      <Header />
      <Title titlenya="Contact Us" />
      <Contact />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<HomeRoute />} />
        <Route path="/about" element={<AboutRoute />} />
        <Route path="/menu" element={<MenuRoute />} />
        <Route path="/cooking-course" element={<CookingRoute />} />
        <Route path="/contact" element={<ContactRoute />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
