import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
    Container,
    Image,
} from 'react-bootstrap';
import Batas from '../../assets/img/batas.png';

function Footer() {
    return (
        <div className="footer mt-5">
            <Image className="footer-batas" src={Batas} fluid />
            <Container className="text-center">
                <p className="mb-0  py-4 text-white">Copyright © Warung Semesta - 2021</p>
            </Container>
        </div>
    );
}

export default Footer;
