import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/css/main.css";
import { Container, Row, Col } from "react-bootstrap";
import data from "../../newDataMenu.json";

function Menu() {
  const [loading] = useState(false);

  return (
    <Container className="content">
      {/* {JSON.stringify(data)} */}
      {data.menu.map((item, indexItem) => (
        <div key={indexItem} className="mb-5">
          <Row>
            <Col lg="12" className="text-center menu-title" data-aos="fade-up">
              <h4>
                {item.title} {item.globalPrice && `- ${item.globalPrice}k`}
              </h4>
            </Col>
          </Row>

          <div class="row justify-content-center">
            {item.remark && (
              <div class="col col-md-8 text-center mb-4">{item.remark}</div>
            )}
          </div>
          <Row>
            {item.menu.map((menu, indexMenu) => (
              <Col key={indexMenu} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{menu.name}</h5>
                  {menu.price > 0 && <h4>{menu.price}k</h4>}
                  <p className="mb-0">{menu.desc}</p>
                </div>
              </Col>
            ))}
          </Row>
          {item.add && (
            <>
              <div class="row justify-content-center">
                <div class="col col-md-8">
                  <div class="row justify-content-center">
                    {item.add && (
                      <div class="col text-center mb-4 mt-3 fs-4">
                        Add Extra
                      </div>
                    )}
                  </div>
                  <Row>
                    {item.add.map((addItem, indexAddItem) => (
                      <Col
                        key={indexAddItem}
                        lg="6"
                        className="p-2"
                        data-aos="fade-up"
                      >
                        <div className="menu-list">
                          <h5>{addItem.name}</h5>
                          {addItem.price > 0 && <h4>{addItem.price}k</h4>}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      ))}

      {/* {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.appetizer.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Add some extras to your salad</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.addsalad.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                </div>
              </Col>
            );
          })}
        </Row>
      )}

      <Row>
        <Col lg="12" className="text-center menu-title mt-5" data-aos="fade-up">
          <h4>SOUP</h4>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.soup.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-5" data-aos="fade-up">
          <h4>Sandwich and snack</h4>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.sandwich.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-5" data-aos="fade-up">
          <h4>Main course</h4>
        </Col>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Non veggie</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.nonveggie.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Veggie</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.veggie.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Asian Stirfry Corner</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.asian.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Add extras to your meal</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.addmeal.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Selection of Pasta</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.pasta.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Selection of Pasta Non Veggie</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.pastanonveg.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h5>Add extras to your pasta</h5>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.maincourse.addpasta.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      <Row>
        <Col lg="12" className="text-center menu-title mt-4" data-aos="fade-up">
          <h4>Dessert</h4>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <p>Loading ... !</p>
        </Row>
      ) : (
        <Row>
          {data.data.dessert.map((apper, index) => {
            return (
              <Col key={index} lg="6" className="p-2" data-aos="fade-up">
                <div className="menu-list">
                  <h5>{apper.name}</h5>
                  <h4>{apper.price}k</h4>
                  <p className="mb-0">{apper.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      )} */}
      <Row>
        <Col lg="12" className="text-center mt-4" data-aos="fade-up">
          <p>
            All price are in thousand rupiah. Subject to 15% goverment tax and
            service
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Menu;
