import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
  Container,
} from 'react-bootstrap';

function About(props) {
  return (
    <Container className="content">
      <h4 data-aos="fade-up">Warung Semesta History</h4>
      <p data-aos="fade-up">
        The Warung Semesta Restaurant is opening May 2010. We are excited to offer you this environmentally friendly restaurant. The initial idea of opening the restaurant came from a conversation with the chairman of Bali Organic Asssosation (Ibu Luh Kartini). She expressed her concern with regards to the obstacles that their local farmers had to face in marketing their organic produce. So we decided we wanted to help.
        </p>
      <p data-aos="fade-up">
        In the long term, our contribution to be health foods and organic produce creates the demand neeed to support the organic argiculture movement in Bali. We hope this will encourage the farmers to adopt organic farming.
        Organic farming is still in the beginning stage in Bali, as such some produce may not be avalable every day.
        </p>
      <p data-aos="fade-up">
        We also make further efforts to optimize the preservation of our environment. For example we endeavor to use supplies and furniture made of recycled material where possible. We also a have composting program for our bio degradable waster, a tree planting program and we look for ways to minimize the use of plastic. For example straw are provided only on request.
        </p>
      <p data-aos="fade-up">
        Most Meals are prepared fresh for your convenience, so your understanding is appriciated whilst our staff cook your order to perfection.
        </p>
      <p data-aos="fade-up">
        As our Customer you are privileged to the use our complementary shower facility on the upper floor of the the restaurant. This may be useful if you have checked out of your hotel and your flight is departing later.
        </p>
      <p data-aos="fade-up">
        It is our wish to continually improve our programs, meal quality and service. Your feedback and suggestions are very welcome. Our feedback forms are available around the restaurant.
        </p>
      <p data-aos="fade-up">
        We wish our ocntribution makes your stay in Bali a most enjoyable experience.
        </p>
    </Container>
  );
}

export default About;
