import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css';
import {
    Container,
    Image
} from 'react-bootstrap';
import bgImage from '../../assets/img/slide1.jpg';
import Batas from '../../assets/img/batas.png';

function Title(props) {

    const bgTitle = {
        background: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    const Title = props.titlenya

    return (
        <div style={bgTitle} className="title mb-5">
            <Container className="text-center pt-4">
                <h4 className="py-5">{Title}</h4>
            </Container>
            <Image className="title-batas" src={Batas} fluid />
        </div>
    );
}

export default Title;
